/* reset */
/* button {
  all: unset;
} */

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 220px;
  @apply border bg-white border-gray-200;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 100;
}
.DropdownMenuContent[data-side="top"],
.DropdownMenuSubContent[data-side="top"] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side="right"],
.DropdownMenuSubContent[data-side="right"] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side="bottom"],
.DropdownMenuSubContent[data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side="left"],
.DropdownMenuSubContent[data-side="left"] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  line-height: 1;
  @apply h-9 cursor-pointer items-center text-xs text-gray-800;
  border-radius: 3px;
  display: flex;

  padding: 0 10px;
  position: relative;
  /* padding-left: 25px; */
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state="open"] {
  @apply text-gray-800 bg-gray-200;
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  @apply text-gray-400;
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  @apply text-white bg-zinc-700;
}

.DropdownMenuLabel {
  /* padding-left: 25px; */
  padding: 0 10px;
  line-height: 25px;
  @apply text-[0.7rem]  uppercase tracking-wide text-gray-600;
}

.DropdownMenuSeparator {
  height: 1px;
  @apply bg-gray-200;
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  @apply fill-white dark:fill-zinc-700;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @apply bg-white text-gray-800;

  box-shadow: 0 2px 10px var(--blackA7);
}
.IconButton:hover {
  background-color: var(--violet3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  @apply text-gray-600;
}
[data-highlighted] > .RightSlot {
  /* @apply bg-white; */
  @apply text-white;
}
[data-disabled] .RightSlot {
  @apply text-gray-300;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
