#loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader span {
  display: block;
  width: 1px;
  height: 5px;

  animation: loader 4s ease-in-out infinite;
  margin: 0 1px;
  border-radius: 3px;
}

@keyframes loader {
  0% {
    height: 6px;
  }
  20% {
    height: 26px;
  }
  40% {
    height: 6px;
  }
  100% {
    height: 6px;
  }
}
