.task-list-has-focus {
  /* outline: 2px solid #d1d5db; */

  @apply relative z-50 ring ring-gray-200;
  /* box-shadow: 0px 0px 0px 1px #d1d5db; */
}

.ProseMirror .tiptap-link {
  /* color: #0366d6; */
  @apply cursor-pointer underline underline-offset-2 text-green-500 hover:text-green-600;
}

.ProseMirror .tiptap-highlight {
  display: inline;
  padding: 0.25em 0;
  background: #ffc107;
  color: #ffffff;
  z-index: -10;
  box-shadow: 0.3em 0 0 #ffc107, -0.3em 0 0 #ffc107;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.ProseMirror .node-taskItem {
  padding-left: 5px;
}

.ProseMirror .node-taskItem p {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-right: 140px;
}
.ProseMirror .node-taskItem::after {
  position: absolute;
  left: -300px;
  content: "";
  width: calc(100% + 324px);
  height: 1px;
  @apply bg-background dark:bg-backgrounddark;
  float: left;
}
.ProseMirror .node-taskItem::before {
  position: absolute;
  left: -300px;
  content: "";
  width: calc(100% + 324px);
  height: 1px;
  @apply bg-background dark:bg-backgrounddark;
  float: left;
}
.ProseMirror h1 {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
  @apply text-2xl font-medium;
}
.ProseMirror h2 {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  @apply text-xl font-medium;
}
.ProseMirror h3 {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  @apply text-lg font-medium;
}

.ProseMirror p {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 5px;
  padding-right: 5px;
  @apply text-[0.96rem];
}

/* .ProseMirror ul {
  padding-top: 5px;
  padding-bottom: 5px;
} */

.ProseMirror .node-taskItem {
  /* @apply border-gray-200 border-t; */
}

/* [data-node-view-content] div p {
  padding-left: 5px;
  padding-right: 5px;
} */

.my-custom-is-empty-class::before {
  @apply text-gray-300;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.tiptap-comment {
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-style: none;
}

/* TODO instead of border create 2 divs up and down with background so you can select text in the header */
/* .task-has-nested-tasks > div:first-child > p:first-child::before {
  content: "";
  @apply absolute -left-20 top-0 z-0 h-14 w-screen border-y text-2xl  border-gray-200;
} */

.task-has-nested-tasks > div:first-child > p:first-child {
  color: var(--header-color) !important;
  @apply z-20 py-3 text-xl font-medium;
}

.task-has-nested-tasks {
  @apply z-10;
}
