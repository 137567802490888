.status-fold {
}

.status-fold::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  border-style: solid;
  border-width: 0 0px 0px 0;
  /* border-color: #34434440 #f9fafb; */
  transition: all ease 0.15s;
  @apply border-r-gray-50 border-b-black/20 dark:border-b-white/10  dark:border-r-zinc-900;
}
.status-fold:hover::before {
  border-width: 0 12px 12px 0;
  /* border-color: #34434440 #f9fafb; */
}

.status-folded::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  border-style: solid;
  border-width: 0 12px 12px 0;
  /* border-color: #34434440 #f9fafb; */
  transition: all ease 0.15s;
  @apply border-r-gray-50 border-b-black/20 dark:border-b-white/10  dark:border-r-zinc-900;
}
