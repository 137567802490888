.square {
  cursor: pointer;
}

.full-screen-square {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pointer {
  height: 20px;
  width: 20px;
  background: white;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 6px 0;
  margin-top: -12px;
  z-index: -14;

  left: calc(0.5vw);
}

.UserAvatar {
  display: inline-block;
  color: white;
}

.UserAvatar--inner {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserAvatar--light {
  color: gray;
}

.UserAvatar--light .UserAvatar--inner {
  border: 1px solid lightgray;
}

.avatar-parent svg g g rect {
  fill: #ffffff;
}

.avatar-parent svg g g path {
  fill: #ffffff;
  stroke: #ffffff;
}

.card-fade-in {
  animation: fadeIn 700ms;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
