.history-item-enter {
  opacity: 0;
  margin-left: -100px;
}
.history-item-enter-active {
  opacity: 1;
  transition: all 200ms ease-in-out;
  margin-left: 0px;
}
.history-item-exit {
  opacity: 1;
  margin-left: 0px;
}
.history-item-exit-active {
  opacity: 0;
  transition: all 200ms ease-in-out;
  margin-left: -100px;
}
