.ql-container {
  font-size: 17px !important;
  font-family: Proxima Nova, system-ui, sans-serif;
  line-height: 1.6 !important;

  @apply text-gray-800 dark:text-zinc-200;
}

.ql-editor {
  line-height: 1.6 !important;
}
.ql-editor.ql-blank::before {
  font-family: Proxima Nova, system-ui, sans-serif !important;
  @apply text-gray-500 !important;
}

.ql-toolbar .ql-stroke {
  @apply stroke-gray-400 !important;
}

.ql-toolbar .ql-fill {
  @apply fill-gray-400 stroke-gray-300 !important;
}

.ql-toolbar .ql-picker {
  @apply stroke-gray-400 !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @apply stroke-gray-600 !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  @apply fill-gray-600 !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  @apply stroke-gray-600 !important;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  height: 26px !important;
  padding: 3px 5px !important;
  width: 30px !important;
}

.ql-mention-list-container {
  width: 270px;

  border-radius: 4px;

  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  /* z-index: 9001; */
  overflow: auto;
  @apply bg-white text-gray-600 border-gray-300 border;
}
.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 17px;
}
.ql-mention-list {
  z-index: 50;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ql-mention-list-item {
  cursor: pointer;
  line-height: 40px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}
.ql-mention-list-item.disabled {
  cursor: auto;
}
.ql-mention-list-item.selected {
  @apply text-white bg-green-500;
  text-decoration: none;
}
.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  @apply border-gray-300  truncate border;

  user-select: all;

  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  /* margin-left: 6px;
  margin-right: 2px; */
}
.mention::before {
  content: "@";
  @apply text-gray-600;
  width: 50px;
  height: 50px;
}

.mention > span {
  margin: 0 3px;
}

.ql-editor ul[data-checked="true"],
.ql-editor ul[data-checked="false"] {
  pointer-events: none;
  list-style-type: none !important;
  margin: 0 !important;

  padding: 0 !important;
}
.ql-editor ul[data-checked="true"] > li::before,
.ql-editor ul[data-checked="false"] > li::before {
  cursor: pointer;
  pointer-events: all;
  font-family: "fontello";
}
.ql-editor ul[data-checked="true"] li {
  text-decoration: line-through;
}
.ql-editor ul[data-checked="true"] > li::before {
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.99 15.58'%3E%3Cpath id='svg_1' d='M5.66,7.79l1.55,1.51,3.11-3.02m4.66,1.51c0,3.75-3.13,6.79-7,6.79S1,11.54,1,7.79,4.13,1,8,1s7,3.04,7,6.79Z' style='fill:none; stroke:%23000; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px;'/%3E%3C/svg%3E") !important;
  width: 16px;
  height: 16px;
}
.ql-editor ul[data-checked="false"] > li::before {
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.99 15.58'%3E%3Cpath id='svg_1' d='M14.99,7.79c0,3.75-3.13,6.79-7,6.79S1,11.54,1,7.79,4.13,1,8,1s7,3.04,7,6.79Z' style='fill:none; stroke:%23000; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px;'/%3E%3C/svg%3E") !important;
  width: 16px;
  height: 16px;
}

.ql-snow {
  border: 0px !important;
}
.ql-toolbar.ql-snow {
  border: 0px solid !important;
}
